import React from "react";
import { OrderData } from "./types";

declare namespace I18n {
  function t(s: string): string;
}

export function convertStringPropsToNumberProps<T extends Record<string, string>>(obj: T): {[K in keyof T]: number} {
  const newObj = {} as {[K in keyof T]: number};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key as keyof T] = Number(obj[key as keyof T]);
    }
  }
  return newObj;
}


export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n) && !(parseFloat(n) < 0);
}

export function parseRichFloat(textNumber: string): number | undefined {
  if (!textNumber) return undefined;

  const x = textNumber.toString().replace(",", ".");
  return parseFloat(x);
}

export function projectState(state: String): React.ReactNode {
  if (state === "confirmed") {
    return <span className="label label-warning">Confirmed</span>;
  } else if (state === "waiting") {
    return <span className="label label-primary">Waiting</span>;
  } else if (state === "in_production") {
    return <span className="label label-primary">Production</span>;
  } else if (state === "ready_for_delivery") {
    return <span className="label label-success">Ready For Delivery</span>;
  } else if (state === "delivered") {
    return <span className="label label-info">Delivered</span>;
  } else {
    return <span className="label">{state}</span>;
  }
}

export function orderStatus(order: OrderData): React.ReactNode {
  let status_text = I18n.t(`status.${order.status}`);

  if (order.status === "active" || order.status === "vis_onhold") {
    return <span className="label label-danger">{status_text}</span>;
  } else if (
    order.status === "in_design" ||
    order.status === "vis_waiting" ||
    order.status === "vis_ready" ||
    order.status === "vis_changes"
  ) {
    return <span className="label label-warning">{status_text}</span>;
  } else if (order.status === "confirmed") {
    return <span className="label label-primary">{status_text}</span>;
  } else if (
    order.status === "canceled" ||
    order.status === "deleted" ||
    order.status === "vis_cancelled"
  ) {
    return <span className="label label-secondary">{status_text}</span>;
  } else if (order.status === "waiting" || order.status === "in_production") {
    return (
      <span className="label label-info" style={{ color: "#fff" }}>
        {status_text}
      </span>
    );
  } else if (
    order.status === "completed" ||
    order.status === "ready_for_delivery" ||
    order.status === "delivered" ||
    order.status === "vis_approved"
  ) {
    return <span className="label label-success">{status_text}</span>;
  } else {
    return <span className="label">{order.status}</span>;
  }
}

export function orderVisStatus(order: OrderData): React.ReactNode {
  let status_text = I18n.t(`vis_status.${order.vis_status}`);
  switch (order.vis_status) {
    case "empty":
      return;
    case "onhold":
      return (
        <span title={status_text} className="label label-danger">
          H
        </span>
      );
    case "waiting":
      return (
        <span title={status_text} className="label label-default">
          W
        </span>
      );
    case "ready":
      return (
        <span title={status_text} className="label label-warning">
          R
        </span>
      );
    case "changes":
      return (
        <span title={status_text} className="label label-success">
          C
        </span>
      );
    case "approved":
      return (
        <span title={status_text} className="label label-primary">
          A
        </span>
      );
    case "canceled":
      return (
        <span title={status_text} className="label label-secondary">
          Cc
        </span>
      );
    default:
      return;
  }
}

export function priceWithCurrency(price, currencySymbol: string): string {
  return `${formatMoney(price, 2, ".", ",")} ${currencySymbol}`;
}

export function currencyNameToSymbol(name: string): string {
  var currency_symbols = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  };

  return currency_symbols[name] ? currency_symbols[name] : name;
}

export function calculateVat(price: number, vat: number): number {
  return price * vat * 0.01;
}

export function calculateVatFromGross(price: number, vat: number): number {
  return (vat / (vat + 100)) * price;
}

export function sortEnabledHeaderColumn(
  label: string,
  accessor: string,
  sidx: string,
  sord: string,
  sortBy: (column: string) => void
): React.ReactNode {
  return (
    <div onClick={() => sortBy(accessor)}>
      {label}
      {sidx === accessor && sord === "desc" && (
        <span>
          {" "}
          &nbsp;&nbsp;&nbsp;<i className="fa fa-sort-down"></i>
        </span>
      )}
      {sidx === accessor && sord === "asc" && (
        <span>
          {" "}
          &nbsp;&nbsp;&nbsp;<i className="fa fa-sort-up"></i>
        </span>
      )}
      {sidx !== accessor && (
        <span>
          {" "}
          &nbsp;&nbsp;&nbsp;<i className="fa fa-sort"></i>
        </span>
      )}
    </div>
  );
}

export const formatMoney = (
  number: number,
  decPlaces: number = 2,
  decSep: string = ".",
  thouSep: string = ","
): string => {
  decPlaces = Math.abs(decPlaces);
  const sign = number < 0 ? "-" : "";
  const absNumber = Math.abs(number);
  const fixedNumber = absNumber.toFixed(decPlaces);
  const [intPart, decimalPart] = fixedNumber.split(".");
  let intPartFormatted = intPart;
  for (let i = intPart.length - 3; i > 0; i -= 3) {
    intPartFormatted =
      intPartFormatted.slice(0, i) + thouSep + intPartFormatted.slice(i);
  }
  return sign + intPartFormatted + (decimalPart ? decSep + decimalPart : "");
};
